import React from 'react'
import { Link } from 'gatsby'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

const Breadcrumb = ({ pages }) => (
    <div className="pt-6 relative px-4 lg:pt-5 sm:px-6 lg:px-8 bg-gradient-to-b from-gray-50 to-white">
        <div className="mx-auto max-w-prose text-lg lg:text-base">
            <ol className="flex ml-0 space-x-4">
                <li className="mb-0">
                    <div>
                        <Link to="/" className="no-underline">
                            {/* <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span className="sr-only">Home</span> */}
                            <p className="text-sm text-gray-400 hover:text-primary-600">Home</p>
                        </Link>
                    </div>
                </li>
                {pages.map((page) => (
                    <li key={page.name} className="mb-0">
                        <div className="flex items-center">
                            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <Link
                                to={page.href}
                                className="no-underline ml-4 text-sm text-gray-400 hover:text-primary-600"
                                aria-current={page.current ? 'page' : undefined}
                            >
                                {page.name}
                            </Link>
                        </div>
                    </li>
                ))}
            </ol>
        </div>
    </div>
)

export default Breadcrumb
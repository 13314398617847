import React from "react"

const Heading = ({ heading, subheading, bg }) => (
    <div className="relative">
        <div className="absolute inset-0">
            {
                bg
            }
            <div className="absolute inset-0 bg-gradient-to-br from-secondary-200 to-primary-400 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative mx-auto max-w-7xl py-24 px-6 lg:px-8 text-center">
            <h1 className="text-4xl font-bold tracking-tight mb-4 text-white sm:text-4xl lg:text-5xl">{heading}</h1>
            <small className="text-base font-semibold text-secondary-200">{subheading}</small>
        </div>
    </div>
)

export default Heading
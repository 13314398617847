import React from "react"
import { StaticImage } from "gatsby-plugin-image"
// import { ChevronRightIcon } from '@heroicons/react/20/solid'
import Layout from "../components/layout"
import Heading from "../components/heading"
import Breadcrumb from "../components/breadcrumb"

const pages = [
  { name: 'Gallery', href: '/gallery', current: true },
]

const GalleryPage = () => {
  return (
    <Layout
      title="Dimension Lab | Our Past Work"
      description="Examples of small business websites which are actively and exclusively managed by Dimension Lab across different industries."
      pageUrl="/gallery"
      theme='light'
      logo={
        <StaticImage
          src="../images/lightLogo.svg"
          alt="Workflow"
          placeholder="none"
          loading="eager"
          height={50}
        />
      }
    >
      {
        <Heading
          heading="Gallery"
          subheading="Our Work"
          bg={
            <StaticImage
              src="https://images.unsplash.com/photo-1554902843-260acd0993f8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
              alt="Banner for the Gallery Page"
              placeholder="none"
              loading="eager"
              objectFit="fullWidth"
              objectPosition="center"
              className="h-full object-cover"
            />
          }
        />
      }

      {
        <Breadcrumb pages={pages} />
      }

      {
        // Placeholder
        <div className="relative px-4 pb-12 lg:pb-20 sm:px-6 lg:px-8 bg-gradient-to-b from-white to-gray-50">
          <div className="relative mx-auto">
            <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500 lg:text-base">
              <h2>Gallery</h2>
              <p className="mt-8 text-md font-semibold text-secondary-600 italic lg:text-base">
                This content is currently under construction and will be updated soon.
              </p>
              <p className="mt-6 text-md text-gray-500 italic lg:text-base">
                We will update this page as soon as the lab is operational!
              </p>
            </div>
          </div>
        </div>
      }
    </Layout>
  )
}

export default GalleryPage
